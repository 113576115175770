import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AlertContext } from "../../App";
import AlertPopup from "../Alert/alert";
import { Link } from "react-router-dom";

const AwardView = () => {
  const [awards, setAwards] = useState();
  const [recipientName, setRecipientName] = useState();
  const [recipientEmail, setRecipientEmail] = useState();
  const [loading, setLoading] = useState();
  const [awardname, setAwardName] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();
  const [error, setError] = useState(null);
  const [decodeText, setDecodeText] = useState("");

  const localPinID = window.location.pathname.split("/")[2];

  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);
  const localEventId = window.location.pathname.split("/")[2];
  const localAwardId = window.location.pathname.split("/")[4];

  useEffect(() => {
    const getAwards = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/events/${localPinID}/awards`,
          {
            method: "GET",

            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data1 = await res.json();
        console.log(data1);

        const newAward = data1.awards.find((item) => item.id == localAwardId);
        console.log(newAward);
        console.log(localAwardId);
        setAwards(newAward);
        const decodedescription = decodeURIComponent(newAward.description);
        // console.log(decodedescription);
        setDecodeText(decodedescription);
        console.log(decodedescription);
        setLoading(false);
      } catch (err) {
        console.log(err, "err");
      }
    };

    getAwards();
  }, [awardname, description, image]);

  if (!awards) {
    return <div>Loading...</div>;
  }

  const handleErrorpopup = () => {
    setError(null);
  };

  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <Link onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
          &larr; Back
        </Link>
        <h3 className="login-heading mb-4">View Award</h3>
        {/* <h3 style={{ textAlign: "center" }}>{awards.name}</h3> */}

        <div className="main-content">
          <div className="awardimg">
            <img
              src={awards.awardimage}
              alt={awards.name}
              // style={{ width: "200px", height: "200px" }}
            />
          </div>

          <div className="awarddes">
            <h3 style={{ textAlign: "center" }}>{awards.name}</h3>
            <textarea
              name="description"
              rows="5"
              cols="40"
              readOnly={true}
              value={decodeText}
            ></textarea>
          </div>
        </div>
        <div className="form-group mb-4">
          <label htmlFor="name">Recipient Name *</label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={awards.recipientname}
            // onChange={(e) => setRecipientName(e.target.value)}
            maxLength={500}
            readOnly={true}
            required
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="name">Recipient Email *</label>
          <input
            type="text"
            name="email"
            className="form-control"
            value={awards.recipientemail}
            //   onChange={(e) => setRecipientEmail(e.target.value)}
            maxLength={500}
            readOnly={true}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default AwardView;
