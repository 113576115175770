import { useContext, useEffect, useState } from "react";
import AlertPopup from "../Alert/alert";
import { useNavigate } from "react-router";
import axios from "axios";
import { AlertContext } from "../../App";
import { Link } from "react-router-dom";
// import "./index.css";

// Get the current Date:
const today = new Date().toISOString().slice(0, 16);

const EditEvent = () => {
  const [eventName, setEventName] = useState();
  const [description, setDescription] = useState();
  const [total, setTotal] = useState();
  const [fromDateTime, setFromDateTime] = useState("");
  const [toDateTime, setToDateTime] = useState("");

  const [balance, setBalance] = useState(500);
  const [isEditing, setIsEditing] = useState(false);
  const [location, setLocation] = useState("");
  const localPinID = window.location.pathname.split("/")[2];

  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  // Function to get the user's timezone
  function getUserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  const handleNewEvent = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    const file = document.querySelector("input[type=file]").files[0];
    setError(null);

    if (!user) {
      return navigate("/");
    }
    const errors = [];

    if (!eventName || eventName.length === 0) {
      errors.push("Event name is required");
    }

    if (!total || total <= 0) {
      errors.push("Total is required");
    }
    if (!fromDateTime || fromDateTime.length === 0) {
      error.push("From Date is required");
    }
    if (!toDateTime || toDateTime.length === 0) {
      error.push("To date is required");
    }

    // Validation for start date
    if (fromDateTime < today) {
      errors.push("Start date cannot be before today");
    }

    // validation for end date
    if (toDateTime < fromDateTime) {
      errors.push("End date cannot be before start date");
    }

    // Amount
    const Pinstoallocate = parseFloat(total);
    // validation for balance
    if (Pinstoallocate > balance) {
      errors.push("Tokens should be below the balance");
      // } else if (Pinstoallocate <= 0) {
      //   errors.push("Token should not be less then 0");
    } else {
      setError("");
      setBalance(balance - Pinstoallocate);
    }

    const formData = new FormData();
    formData.append("file", file);
    let ipfsHash;

    try {
      const ipfs = await axios.post(
        process.env.REACT_APP_BASEURL + "/nftarticle",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      ipfsHash = `ipfs://${ipfs?.data?.IpfsHash}`;
    } catch (err) {
      console.log("err", err);
    }

    // Encode the description for safe storage
    const encodeDescription = encodeURIComponent(description);
    console.log(encodeDescription);

    // Decode the stored description
    const decodeDescription = decodeURIComponent(encodeDescription);
    setDescription(decodeDescription);
    console.log(decodeDescription);
  };

  useEffect(() => {
    const getEvent = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
        return navigate("/");
      }
      if (localPinID) {
        setIsEditing(true);
        try {
          const res = await fetch(
            `${process.env.REACT_APP_BASEURL}/members/events/${localPinID}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${user.sessionToken}`,
              },
            }
          );

          const data = await res.json();
          // setIsEditing(data);
          console.log(data);
          setEventName(data.name);
          setLocation(data.location);
          const date = new Date(data.timestampfrom);

          // Extract date
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");

          const formatteDate = `${year}-${month}-${day} ${hours}:${minutes}`;
          console.log(formatteDate);
          setFromDateTime(formatteDate);

          const date1 = new Date(data.timestampto);

          // Extract date
          const today = String(date1.getDate()).padStart(2, "0");
          const tomonth = String(date1.getMonth() + 1).padStart(2, "0");
          const toyear = date1.getFullYear();
          const tohours = String(date1.getHours()).padStart(2, "0");
          const tominutes = String(date1.getMinutes()).padStart(2, "0");

          const toformatteDate = `${toyear}-${tomonth}-${today} ${tohours}:${tominutes}`;
          console.log(toformatteDate);

          setToDateTime(toformatteDate);

          setTotal(data.maxtokens);
          console.log(
            JSON.stringify(JSON.parse(data.souvenirs[0].extra).attributes)
          );
        } catch (err) {
          alertContext.show = true;
          alertContext.variant = "danger";
          if (err.toString()?.includes("Forbidden")) {
            alertContext.msg = "Session expired, please sign in";
            localStorage.removeItem("user");
            navigate("/");
          } else {
            alertContext.msg = "Error getting event details. Please try again";
          }
        }
      } else {
        setIsEditing(false);
      }
    };

    getEvent();
  }, [alertContext, localPinID, navigate]);

  // Handle Update Form submission
  const handleUpdate = async (e) => {
    const user = JSON.parse(localStorage.getItem("user"));
    e.preventDefault();
    setError(null);

    try {
      const result = await fetch(
        `${process.env.REACT_APP_BASEURL}/members/events/${localPinID}`,
        {
          method: "PUT",
          body: JSON.stringify({
            name: eventName,
            location: location,
            from: fromDateTime,
            to: toDateTime,
            maxtokens: total,
          }),
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );

      const data = await result.json();
      console.log(data);
      if (data["error"]) {
        setError(data["error"]);
        return;
      } else {
        alertContext.show = true;
        alertContext.variant = "primary";
        alertContext.msg = "Event has been updated successfully!";
        navigate("/events");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  // Handle start date
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;

    // check if selected date is before Today's date
    if (selectedStartDate >= today) {
      // alert("Start date cannot be before today.");
      setFromDateTime(selectedStartDate);
    } else {
      alert("Start date cannot be before today");

      // Reset end date
      if (toDateTime && toDateTime < selectedStartDate) {
        setToDateTime("");
      }
    }
  };

  // Function to disable past time on the current day
  const isTimeDisabled = (time) => {
    const now = new Date();
    if (fromDateTime) {
      const selectedDate = new Date(fromDateTime);
      // If start date is today, disabled past times
      if (
        selectedDate.getDate() === now.getDate() &&
        selectedDate.getMonth() === now.getMonth() &&
        selectedDate.getDate() === now.getFullYear()
      ) {
        return time.getTime() < now.getTime();
      }
    }
    return false;
  };
  // Handle end date

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setToDateTime(selectedEndDate);
  };

  const handleErrorpopup = () => {
    setError(null);
  };

  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <form
          method="put"
          encType="multipart/form-data"
          onSubmit={handleUpdate}
        >
          <Link to="/events" style={{ textDecoration: "none" }}>
            &larr; Back
          </Link>
          <h3 className="login-heading mb-4">Edit Event</h3>
          <div className="form-group mb-4">
            <label htmlFor="name">Event Name *</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              maxLength={500}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="location">Location *</label>
            <input
              name="Location"
              type="text"
              className="form-control"
              required
              //   min={0}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          <div className="form-group mb-4">
            <div className="form-group mb-4">
              Start date &amp; End date
              <br />
              <br />
              <div style={{ display: "flex", gap: "2rem" }}>
                <input
                  type="datetime-local"
                  className="form-control"
                  placeholder="Start Date"
                  value={fromDateTime}
                  onChange={handleStartDateChange}
                  min={today}
                  filterTime={isTimeDisabled}
                  required
                />
                <input
                  type="datetime-local"
                  className="form-control"
                  placeholder="End Date"
                  value={toDateTime}
                  onChange={handleEndDateChange}
                  min={fromDateTime || today}
                  filterTime={(time) => !fromDateTime || time > fromDateTime}
                  disabled={!fromDateTime}
                  required
                />
              </div>
            </div>
          </div>

          <div className="fields-row">
            <div className="form-group mb-3 field">
              <label htmlFor="pinscount">PiNs to allocate *</label>
              <input
                name="pinscount"
                type="number"
                className="form-control"
                required
                min={0}
                value={total}
                onChange={(e) => setTotal(e.target.value)}
              />
            </div>
          </div>
          <hr />

          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEvent;
