import { useContext, useEffect, useState } from "react";
import AlertPopup from "../Alert/alert";
import { useNavigate } from "react-router";
import axios from "axios";
import { AlertContext } from "../../App";
import { Link } from "react-router-dom";
import "./index.css";

// Get the current Date:
const today = new Date().toISOString().slice(0, 16);

const NewEvent = () => {
  const [eventName, setEventName] = useState();
  const [description, setDescription] = useState();
  const [total, setTotal] = useState();
  const [fromDateTime, setFromDateTime] = useState("");
  const [toDateTime, setToDateTime] = useState("");
  const [attribute1, setAttribute1] = useState();
  const [attribute2, setAttribute2] = useState();
  const [attribute3, setAttribute3] = useState();
  const [attribute4, setAttribute4] = useState();
  const [attribute5, setAttribute5] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [balance, setBalance] = useState(500);
  // const [isEditing, setIsEditing] = useState(false);
  const [location, setLocation] = useState("");
  const localPinID = window.location.pathname.split("/")[2];

  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  // Function to get the user's timezone
  function getUserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  const handleNewEvent = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    const file = document.querySelector("input[type=file]").files[0];
    setError(null);

    if (!user) {
      return navigate("/");
    }
    const errors = [];

    if (!attribute1) {
      errors.push("At least one metadata field is required");
    }

    if (!eventName || eventName.length === 0) {
      errors.push("Event name is required");
    }
    if (!description || description?.length === 0) {
      errors.push("Description is required");
    }

    if (!file || file?.length === 0) {
      errors.push("File is required");
    }

    // File size should be less than 10MB
    if (file?.size > 10000000) {
      errors.push("File size should be less than 10MB");
    }

    if (
      !file.name.toLowerCase().includes("jpg") &&
      !file.name.toLowerCase().includes("png") &&
      !file.name.toLowerCase().includes("gif") &&
      !file.name.toLowerCase().includes("jpeg")
    ) {
      errors.push("File must be png or jpg or gif or jpeg");
    }

    if (!total || total <= 0) {
      errors.push("Total is required");
    }
    if (!fromDateTime || fromDateTime.length === 0) {
      error.push("From Date is required");
    }
    if (!toDateTime || toDateTime.length === 0) {
      error.push("To date is required");
    }

    // Validation for start date
    if (fromDateTime < today) {
      errors.push("Start date cannot be before today");
    }

    // validation for end date
    if (toDateTime < fromDateTime) {
      errors.push("End date cannot be before start date");
    }

    // Amount
    const Pinstoallocate = parseFloat(total);
    // validation for balance
    if (Pinstoallocate > balance) {
      errors.push("Tokens should be below the balance");
      // } else if (Pinstoallocate <= 0) {
      //   errors.push("Token should not be less then 0");
    } else {
      setError("");
      setBalance(balance - Pinstoallocate);
    }

    const key = attribute1?.key;
    const val = attribute1?.val;
    const extra = {
      attributes: [{ trait_type: key, value: val }],
    };

    if (attribute2) {
      extra.attributes.push({
        trait_type: attribute2?.key,
        value: attribute2?.val,
      });
    }

    if (attribute3) {
      extra.attributes.push({
        trait_type: attribute3?.key,
        value: attribute3?.val,
      });
    }

    if (attribute4) {
      extra.attributes.push({
        trait_type: attribute4?.key,
        value: attribute4?.val,
      });
    }

    if (attribute5) {
      extra.attributes.push({
        trait_type: attribute5?.key,
        value: attribute5?.val,
      });
    }

    // Do we need this?
    try {
      JSON.parse(JSON.stringify(extra));
      console.log("valid");
    } catch {
      errors.push("Extra is not valid JSON");
    }

    console.log("errors", errors);
    if (errors.length > 0) {
      setError(errors);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    let ipfsHash;

    try {
      const ipfs = await axios.post(
        process.env.REACT_APP_BASEURL + "/nftarticle",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      ipfsHash = `ipfs://${ipfs?.data?.IpfsHash}`;
    } catch (err) {
      console.log("err", err);
    }

    // Encode the description for safe storage
    const encodeDescription = encodeURIComponent(description);
    console.log(encodeDescription);

    const res = await fetch(
      process.env.REACT_APP_BASEURL + "/members/souvenirevents",
      {
        method: "post",
        body: JSON.stringify({
          name: eventName,
          description,
          artifact: ipfsHash,
          maxtokens: total,
          limitperperson: 1,
          from: new Date(fromDateTime).toISOString(),
          to: new Date(toDateTime).toISOString(),
          extra: extra,
          timezone: getUserTimezone(),
          location: location,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );

    const data = await res.json();
    console.log(data);

    // Decode the stored description
    const decodeDescription = decodeURIComponent(encodeDescription);
    setDescription(decodeDescription);
    console.log(decodeDescription);

    if (data["error"]) {
      setError(data["error"]);
      return;
    } else {
      alertContext.show = true;
      alertContext.variant = "primary";
      alertContext.msg = "A new event has been created!";
      navigate("/events");
    }
  };

  // Handle start date
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;

    // check if selected date is before Today's date
    if (selectedStartDate >= today) {
      // alert("Start date cannot be before today.");
      setFromDateTime(selectedStartDate);
    } else {
      alert("Start date cannot be before today");

      // Reset end date
      if (toDateTime && toDateTime < selectedStartDate) {
        setToDateTime("");
      }
    }
  };
  // Handle file upload
  const handleFilechange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // create a preview URL for the upload
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  // Function to disable past time on the current day
  const isTimeDisabled = (time) => {
    const now = new Date();
    if (fromDateTime) {
      const selectedDate = new Date(fromDateTime);
      // If start date is today, disabled past times
      if (
        selectedDate.getDate() === now.getDate() &&
        selectedDate.getMonth() === now.getMonth() &&
        selectedDate.getDate() === now.getFullYear()
      ) {
        return time.getTime() < now.getTime();
      }
    }
    return false;
  };
  // Handle end date

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setToDateTime(selectedEndDate);
  };

  const handleErrorpopup = () => {
    setError(null);
  };

  const handleMetadata = (e) => {
    const id = e.target.id;
    const val = e.target.value;
    const fieldNum = e.target.id.split("-")?.[1];
    if (fieldNum === "1") {
      if (id.includes("key")) {
        setAttribute1((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute1((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }

    if (fieldNum === "2") {
      if (id.includes("key")) {
        setAttribute2((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute2((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
    if (fieldNum === "3") {
      if (id.includes("key")) {
        setAttribute3((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute3((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
    if (fieldNum === "4") {
      if (id.includes("key")) {
        setAttribute4((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute4((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
    if (fieldNum === "5") {
      if (id.includes("key")) {
        setAttribute5((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute5((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
  };

  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleNewEvent}
        >
          <Link to="/events" style={{ textDecoration: "none" }}>
            &larr; Back
          </Link>
          <h3 className="login-heading mb-4">New Event</h3>
          <div className="form-group mb-4">
            <label htmlFor="name">Event Name *</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              maxLength={500}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="location">Location *</label>
            <input
              name="Location"
              type="text"
              className="form-control"
              required
              min={0}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>

          <div className="form-group mb-4">
            <div className="form-group mb-4">
              Start date &amp; End date
              <br />
              <br />
              <div style={{ display: "flex", gap: "2rem" }}>
                <input
                  type="datetime-local"
                  className="form-control"
                  placeholder="Start Date"
                  value={fromDateTime}
                  onChange={handleStartDateChange}
                  min={today}
                  filterTime={isTimeDisabled}
                  required
                />
                <input
                  type="datetime-local"
                  className="form-control"
                  placeholder="End Date"
                  value={toDateTime}
                  onChange={handleEndDateChange}
                  min={fromDateTime || today}
                  filterTime={(time) => !fromDateTime || time > fromDateTime}
                  disabled={!fromDateTime}
                  required
                />
              </div>
            </div>
          </div>

          <div className="fields-row">
            <div className="form-group mb-3 field">
              <label htmlFor="pinscount">PiNs to allocate *</label>
              <input
                name="pinscount"
                type="number"
                className="form-control"
                required
                min={0}
                value={total}
                onChange={(e) => setTotal(e.target.value)}
              />
            </div>
          </div>
          <hr />

          <div className="form-floating mb-3">
            <h2>Souvenir Details</h2>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="description">Description *</label>

            <textarea
              rows={5}
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          <div className="form-group mb-4">
            <h3>Digital Art or Asset</h3>

            <label htmlFor="artifact">Image (JPG or PNG or GIF) *</label>

            <input
              type="file"
              className="form-control"
              required
              name="artifact"
              accept="image/*"
              placeholder="jpeg or png or gif image"
              onChange={handleFilechange}
            />

            {imagePreview && (
              <div>
                <img
                  src={imagePreview}
                  alt="preview"
                  style={{
                    height: "100px",
                    width: "100px",
                    marginTop: "10px",
                  }}
                />
              </div>
            )}
          </div>

          <div className="form-floating mb-3">
            <h3>Properties</h3>
          </div>
          <div className="form-group mb-4">
            <div className="form-group mb-4">
              <label htmlFor="location">
                Keys &amp; Values will be stored inside the Souvenir.
              </label>

              <br />
              <br />
              <div style={{ display: "flex", gap: "2rem" }}>
                <input
                  type="text"
                  className="form-control"
                  required
                  placeholder="Key"
                  id="key-1"
                  onChange={handleMetadata}
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="Value"
                  id="val-1"
                  required
                  onChange={handleMetadata}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  margin: "1rem 0 1rem 0",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  id="key-2"
                  placeholder="Key"
                  onChange={handleMetadata}
                />

                <input
                  type="text"
                  className="form-control"
                  id="val-2"
                  placeholder="Value"
                  onChange={handleMetadata}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  margin: "1rem 0 1rem 0",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Key"
                  id="key-3"
                  onChange={handleMetadata}
                />

                <input
                  type="text"
                  className="form-control"
                  placeholder="Value"
                  id="val-3"
                  onChange={handleMetadata}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  margin: "1rem 0 1rem 0",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  id="key-4"
                  placeholder="Key"
                  onChange={handleMetadata}
                />

                <input
                  type="text"
                  className="form-control"
                  id="val-4"
                  placeholder="Value"
                  onChange={handleMetadata}
                />
              </div>
              <div style={{ display: "flex", gap: "2rem" }}>
                <input
                  type="text"
                  className="form-control"
                  id="key-5"
                  placeholder="Key"
                  onChange={handleMetadata}
                />

                <input
                  type="text"
                  className="form-control"
                  id="val-5"
                  placeholder="Value"
                  onChange={handleMetadata}
                />
              </div>
            </div>
          </div>
          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewEvent;
