import { useContext, useEffect, useState } from "react";
import AlertPopup from "../Alert/alert";
import { AlertContext } from "../../App";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./emailpins.css";

const EmailPins = () => {
  const [emailList, setEmailList] = useState();
  const alertContext = useContext(AlertContext);
  const navigate = useNavigate();
  const [maxTokens, setMaxTokens] = useState();
  const [error, setError] = useState("");

  const handleInput = (e) => {
    setEmailList(e.target.value.replace(/\s+/g, "\n"));
  };

  useEffect(() => {
    const getPins = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const localPinID = window.location.pathname.split("/")[3];

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/members/pins/${localPinID}/stats`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );
        const data = await res.json();
        setMaxTokens(data?.maxtokens - data?.issued);
      } catch (err) {
        alertContext.show = true;
        alertContext.variant = "danger";
        if (err.toString()?.includes("Forbidden")) {
          alertContext.msg = "Session expired, please sign in";
          localStorage.removeItem("user");
          navigate("/");
        } else {
          alertContext.msg = "Error getting pins";
        }
      }
    };

    getPins();
  }, [alertContext, navigate]);

  const handleSubmit = async () => {
    const re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    const localPinID = window.location.pathname.split("/")[3];
    const invalidEmails = [];
    const validEmails = [];
    const emails = emailList.split("\n").filter((email) => email.length > 0);
    for (const email of emails) {
      const validEmail = re.test(email);
      if (!validEmail) {
        invalidEmails.push(email);
      } else {
        validEmails.push(email);
      }
    }

    if (invalidEmails.length > 0) {
      if (invalidEmails.length > 5) {
        setError("Multiple invalid emails");
      }
      setError(`${[...invalidEmails, " invalid email"]}`);
      return;
    }

    if (validEmails.length > maxTokens) {
      setError("Not enough tokens for number of emails");
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));

    const res = await fetch(
      process.env.REACT_APP_BASEURL + "/members/emailpins",
      {
        method: "post",
        headers: {
          Authorization: `Bearer ${user.sessionToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          eventid: localPinID,
          emails,
        }),
      }
    );
    const data = await res.json();

    if (data.success) {
      alertContext.show = true;
      alertContext.msg = "Email(s) sent successfully!";
      alertContext.variant = "primary";
      setError(null);
      navigate("/events");
      return;
    } else {
      setError("Error sending emails");
      return;
    }
  };
  const handleback = () => {
    navigate(-1);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "50%" }}>
          {error && (
            <AlertPopup
              message={error}
              variant="danger"
              setShow={() => setError(null)}
            />
          )}
          <Link onClick={handleback} style={{ textDecoration: "none" }}>
            &larr; Back
          </Link>
          <h1 style={{ fontSize: "2.5rem", marginBottom: "3rem" }}>
            Email PiNs
          </h1>
          <p>
            Please enter a list of emails one for each line. No comma at the
            end.
          </p>
          <textarea
            className="form-control"
            rows={10}
            placeholder="Enter a list of emails"
            value={emailList}
            onChange={handleInput}
          ></textarea>
          <div className="button-container">
            <button
              className="left-button btn btn-danger"
              type="button"
              onClick={handleback}
            >
              Cancel
            </button>
            <button
              className="right-button btn btn-primary"
              type="button"
              onClick={handleSubmit}
            >
              Send Emails
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailPins;
